import Litepicker from 'litepicker/dist/nocss/litepicker.umd';
import './plugins/mobile';

const dateRange = (min, max, containerId) => ({
  container: null,
  range: null,
  start: null,
  end: null,
  picker: null,
  format: 'DD.MM.YYYY',
  lang: bergfex?.datepicker?.language ?? 'de',
  dateStart: null,
  dateEnd: null,

  init() {
    this.range = this.$root.querySelector('[x-ref="range"]');
    this.start = this.$root.querySelector('[x-ref="start"]');
    this.end = this.$root.querySelector('[x-ref="end"]');

    this.setupContainer();
    this.$nextTick(() => {
      this.intiDateRange();
    });
  },

  close() {
    this.picker.hide();
  },

  setupContainer() {
    if (this.container) {
      return;
    }

    const wrapper = document.createElement('div');
    this.container = document.createElement('div');
    this.container.classList.add('tw-datepicker-container', 'tw-peer');

    const inner = document.createElement('div');
    inner.classList.add('tw-datepicker-background');
    inner.setAttribute('id', containerId);

    wrapper.append(this.container);
    wrapper.append(inner);

    document.body.append(wrapper);
  },

  reset() {
    this.picker.clearSelection();
    this.start.value = '';
    this.end.value = '';
  },

  intiDateRange() {
    this.picker = new Litepicker({
      element: this.range,
      parentEl: this.container,
      singleMode: false,
      lang: this.lang,
      format: this.format,
      plugins: ['mobile'],
      mobile: {
        minDate: min?.length <= 0 ? new Date() : min,
        maxDate: max ?? null,
      },
    });

    this.picker.on('selected', (date1, date2) => {
      this.start.value = date1.format(this.format, this.lang);
      this.end.value = date2.format(this.format, this.lang);
      this.$dispatch('daterange-selected', { date1, date2 });
      this.dateStart = this.start.value;
      this.dateEnd = this.end.value;
    });

    this.picker.on('clear:selection', () => {
      this.start.value = '';
      this.end.value = '';
    });

    this.picker.on('preselect', (date1, date2) => {
      this.dateStart = date1;
      this.dateEnd = date2;
    });

    this.picker.on('button:cancel', () => {
      this.dateStart = null;
      this.dateEnd = null;
    });

    if (this.start.value && this.end.value) {
      this.picker.setDateRange(this.start.value, this.end.value);
    }
  },

  clickInput() {
    this.$nextTick(() => {
      this.picker.show();
    });
  },
});

export default dateRange;
