const modalId = 'notification';

const notify = (el, { Alpine }) => {
  return {
    error: (title, content) => {
      toggle(title, content, false);
    },
    success: (title, content) => {
      toggle(title, content, true);
    },
  };
};

const toggle = (title, content, success) => {
  const modalStore = Alpine.store('modal');

  if (!modalStore.isOpen(modalId)) {
    open(title, content, success);
    return;
  }

  modalStore.close(modalId);
  setTimeout(() => open(title, content, success), 300);
};

const open = (title, content, success) => {
  const notificationStore = Alpine.store('notification');
  const modalStore = Alpine.store('modal');

  if (modalStore.isOpen(modalId)) {
    modalStore.close(modalId);
  }

  notificationStore.success = success;
  notificationStore.title = title;
  notificationStore.content = content;
  modalStore.open(modalId);
};

export default notify;
