export const BREAKPOINT_XS = 421;
export const BREAKPOINT_SM = 641;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 1060;
export const BREAKPOINT_XL = 1248;
export const BREAKPOINT_2XL = 1400;
export const BREAKPOINT_3XL = 1600;

export const isResponsiveScreen = (breakpoint = BREAKPOINT_MD) => {
  return (
    window.innerWidth <= breakpoint ||
    (window.matchMedia &&
      window.matchMedia('only screen and (max-width: ' + breakpoint + 'px)').matches)
  );
};

export const getMaxItemsPerGridPage = () => {
  if (window.innerWidth <= BREAKPOINT_MD) {
    return 2;
  }

  if (window.innerWidth <= BREAKPOINT_LG) {
    return 3;
  }

  return 4;
};

export const checkElementVisibleByClass = (className) => {
  const breakpoints = {
    xs: BREAKPOINT_XS,
    sm: BREAKPOINT_SM,
    md: BREAKPOINT_MD,
    lg: BREAKPOINT_LG,
    xl: BREAKPOINT_XL,
    '2xl': BREAKPOINT_2XL,
    '3xl': BREAKPOINT_3XL,
  };

  let breakpointVisible = null;
  let breakpointHidden = null;

  const classes = className.split(' ');
  const hiddenClass = classes.filter((c) => c.includes('tw-hidden'))[0];
  const visibleClass = classes.filter((c) => c.includes('tw-block'))[0];

  if (!hiddenClass && !visibleClass) {
    return true;
  }

  if (visibleClass.includes(':')) {
    breakpointVisible = visibleClass.split(':')[0];
  }

  if (hiddenClass.includes(':')) {
    breakpointHidden = hiddenClass.split(':')[0];
  }

  if (breakpointHidden && breakpointVisible) {
    breakpointHidden = breakpoints[breakpointHidden];
    breakpointVisible = breakpoints[breakpointVisible];

    if (breakpointVisible > breakpointHidden) {
      if (window.outerWidth < breakpointHidden || window.outerWidth > breakpointVisible) {
        return true;
      }
    }

    if (window.outerWidth >= breakpointVisible && window.outerWidth < breakpointHidden) {
      return true;
    }
  }

  if (breakpointHidden) {
    if (window.outerWidth < breakpoints[breakpointHidden]) {
      return true;
    }
  }

  if (breakpointVisible) {
    if (window.outerWidth >= breakpoints[breakpointVisible]) {
      return true;
    }
  }

  return false;
};

export const defaultGridItems = {
  1: 8,
  2: 8,
  3: 9,
  4: 8,
};
