import scrollLock from '../../abstracts/scrollLock';
import { isResponsiveScreen } from '../../abstracts/responsive';

const header = () => ({
  navigationOpen: false,
  searchOpen: false,
  searchInputDummy: null,
  searchFocusElement: null,
  searchURL: new URL('/suchen/', document.location),
  lastScrollTop: 0,
  headerHeight: null,
  headerTop: 0,

  init() {
    this.searchInputDummy = this.$root.querySelector('input[x-ref="dummy"]');
    this.searchFocusElement = this.$root.querySelector('[x-ref="focusElement"]');
    this.headerHeight = this.$el.offsetHeight + 1;
    this.lastScrollTop = window.scrollY;
  },

  toggleNavigation() {
    this.navigationOpen = !this.navigationOpen;
    scrollLock.toggle(this.navigationOpen);
  },

  closeNavigation() {
    this.navigationOpen = false;
    scrollLock.unlock();
  },

  toggleSearch() {
    this.searchOpen = !this.searchOpen;
    scrollLock.toggle(this.searchOpen);

    if (!this.searchOpen) {
      return;
    }

    if (isResponsiveScreen()) {
      this.searchFocusElement.click();
      return;
    }

    this.$nextTick(() => {
      this.searchInputDummy.focus();
    });
  },

  closeSearch() {
    this.searchOpen = false;
    scrollLock.toggle(false);
  },

  onSearchResultSelected(event) {
    if (event.detail.attributes?.path) {
      window.location.href = event.detail.attributes?.path;
      return;
    }

    this.searchURL.searchParams.set('q', event.detail.value ?? '');
    window.location.assign(this.searchURL.toString());

    window.location.href = this.searchURL.toString();
  },

  onScroll() {
    if (window.bergfex.scrollLock) {
      return;
    }
    const st = window.scrollY;
    const stDiff = this.lastScrollTop - st;
    const elTop = this.headerTop + stDiff;

    if (stDiff > 0) {
      // scroll up
      this.headerTop = elTop > 0 ? 0 : elTop;
    } else if (st > 100) {
      // scrollDown
      this.headerTop = Math.abs(elTop) > this.headerHeight ? -this.headerHeight : elTop;
    }

    Alpine.store('header').top = this.headerTop + 80;

    this.lastScrollTop = st;
  },
});

export default header;
