import tagmanager from '../abstracts/tagmanager';

const track = () => {
  const identifier = {
    class: '.js-track',
  };

  const init = () => {
    document.addEventListener('DOMContentLoaded', () => {
      initTracking();
    });
  };

  const initTracking = () => {
    const elements = document.querySelectorAll(identifier.class);

    elements.forEach((element) => {
      element.addEventListener('click', (e) => {
        sendTracking(e.currentTarget);
      });
    });
  };

  init();
};

export default track;

export const sendTracking = (element) => {
  let trackingEvent = element.getAttribute('data-tracking-event');
  let trackingCategory = element.getAttribute('data-tracking-category');
  let trackingData = element.getAttribute('data-tracking-data') || '{}';

  if (!trackingEvent) {
    return;
  }

  if (trackingCategory) {
    tagmanager.event(trackingEvent, { Selection: trackingCategory, ...JSON.parse(trackingData) });
  } else {
    // send just event without any Selection
    tagmanager.event(trackingEvent, JSON.parse(trackingData));
  }
};
