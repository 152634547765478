import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { isResponsiveScreen } from '../../../abstracts/responsive';
import PhotoSwipeFullscreen from './fullscreen';
import PhotoSwipeCaption from './caption';

const icon_zoom =
  '<svg class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path class="pswp__zoom-icn-bar-v" fill="#fff" fill-rule="evenodd" d="M17 9A8 8 0 1 1 1 9a8 8 0 0 1 16 0Zm-2 6.708a9 9 0 1 1 .707-.707l4.147 4.145a.5.5 0 0 1-.707.708L15 15.708ZM5 9a.5.5 0 0 1 .5-.5h3v-3a.5.5 0 0 1 1 0v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3A.5.5 0 0 1 5 9Z" clip-rule="evenodd"/><path fill="#fff" fill-rule="evenodd" d="M9 17A8 8 0 1 0 9 1a8 8 0 0 0 0 16Zm0 1a8.966 8.966 0 0 0 6-2.292l4.146 4.146a.5.5 0 0 0 .708-.708l-4.146-4.145A9 9 0 1 0 9 18ZM5.5 8.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7Z" clip-rule="evenodd"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>';
const icon_close =
  '<svg class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#fff" fill-rule="evenodd" d="M17.852 17.138a.505.505 0 1 1-.714.714L10 10.714l-7.138 7.138a.505.505 0 0 1-.714-.714L9.286 10 2.148 2.862a.505.505 0 1 1 .714-.714L10 9.286l7.138-7.138a.505.505 0 1 1 .714.714L10.714 10l7.138 7.138Z" clip-rule="evenodd"/></svg>';
const icon_prev =
  '<svg class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#fff" fill-rule="evenodd" d="M13.86 2.153a.498.498 0 0 0-.704-.013L5 10l8.155 7.86a.498.498 0 0 0 .706-.013.5.5 0 0 0-.014-.707L6.439 10l7.408-7.14a.5.5 0 0 0 .014-.707Z" clip-rule="evenodd"/></svg>';

export default () => {
  window.bergfex.lightbox?.destroy();

  const lightbox = new PhotoSwipeLightbox({
    gallery: '[data-lightbox]',
    children: '[data-lightbox-item]',
    pswpModule: () => import('photoswipe'),
    showHideAnimationType: isResponsiveScreen() ? 'none' : 'fade',
    zoomSVG: icon_zoom,
    closeSVG: icon_close,
    arrowPrevSVG: icon_prev,
    arrowNextSVG: icon_prev,
    clickToCloseNonZoomable: false,
    preloadFirstSlide: false,
  });

  new PhotoSwipeFullscreen(lightbox);
  new PhotoSwipeCaption(lightbox);
  lightbox.init();

  window.bergfex = window.bergfex || {};
  window.bergfex.lightbox = lightbox;
};
