import Litepicker from 'litepicker/dist/nocss/litepicker.umd';
import scrollLock from '../../../../abstracts/scrollLock';
import { isResponsiveScreen } from '../../../../abstracts/responsive';

Litepicker.add('mobile', {
  init: function (picker) {
    const options = picker.options;
    const defaultOptions = {
      monthsDesktop: 2,
      monthsMobile: 12,
      openClass: 'tw-is-open',
      autoApply: true,
      autoRefresh: true,
    };
    let open = false;
    picker.options.mobile = { ...defaultOptions, ...options.mobile };

    // format minDate according the `format` option
    if (picker.options.mobile.minDate) {
      picker.options.minDate = this.DateTime(picker.options.mobile.minDate, this.options.format);
    }

    // format maxDate according the `format` option
    if (picker.options.mobile.maxDate) {
      picker.options.maxDate = this.DateTime(picker.options.mobile.maxDate, this.options.format);
    }

    // set default options
    picker.options.buttonText = {
      ...bergfex?.datepicker?.daterange?.buttonText,
      ...{
        nextMonth: '<i class="icon-chevron-right"></i>',
        previousMonth: '<i class="icon-chevron-left"></i>',
      },
    };
    picker.options.switchingMonths = 1;
    picker.options.scrollToDate = false;

    window.addEventListener('popstate', (event) => {
      if (open) {
        picker.hide();
      }
    });

    picker.ui.addEventListener('click', (event) => {
      if (event.target.closest('.button-cancel')) {
        picker.clearSelection();
        picker.show();
      }
    });

    picker.on('before:show', () => {
      handleNumberOfMonths();

      if (isResponsiveScreen()) {
        picker.options.autoApply = false;
        picker.options.allowRepick = false;
        scrollLock.lock();
      } else {
        picker.options.autoApply = picker.options.mobile.autoApply;
        picker.options.allowRepick = picker.options.mobile.allowRepick;
      }

      picker.options.parentEl.classList.add(picker.options.mobile.openClass);
      if (picker.options.element.value.length > 0 && !picker.options.autoApply) {
        setTimeout(() => {
          picker.ui
            .querySelector('.container__footer')
            .classList.add(picker.options.mobile.openClass);
        }, 100);
      }

      if (isResponsiveScreen()) {
        picker.options.element.blur();
      }
    });

    picker.on('preselect', (date1, date2) => {
      if (picker.options.element && date1 && !date2 && !isResponsiveScreen()) {
        picker.options.element.focus();
      }

      if (picker.options.autoApply) {
        return;
      }

      setTimeout(() => {
        picker.ui
          .querySelector('.container__footer')
          .classList.toggle(
            picker.options.mobile.openClass,
            !!(date1 && (picker.options.singleMode || date2)),
          );
      }, 10);
    });

    picker.on('selected', (date1, date2) => {
      if (date1 && date2 && picker.options.element) {
        picker.options.element._x_model.set(
          date1.format(this.options.format, this.options.lang) +
            ' - ' +
            date2.format(this.options.format, this.options.lang),
        );
      }
      open && isResponsiveScreen() && history.back();
    });

    picker.on('hide', () => {
      open = false;
      if (isResponsiveScreen()) {
        scrollLock.unlock();
      }
      picker.options.parentEl.classList.remove(picker.options.mobile.openClass);
    });

    picker.on('show', (el) => {
      open = true;
      isResponsiveScreen() && history.pushState({ open }, '', '');
      if (!el) {
        return;
      }
      // let datepicker not overflow to the right if there is not enough spacing
      const scrollX = window.scrollX;
      const rect = el.getBoundingClientRect();
      const calRect = picker.ui.getBoundingClientRect();
      if (rect.top > calRect.top) {
        // calendar is shown over input

        let style = getComputedStyle(picker.ui);
        if (style.marginTop) {
          let marginTop = parseInt(style.marginTop.replace('px', ''));
          let top = parseInt(picker.ui.style.top.replace('px', ''));

          // correct top spacing
          picker.ui.style.top = top - marginTop * 2 + 'px';
        }
      }

      if (!(rect.left + calRect.width > window.innerWidth)) {
        // out of bounds
        return;
      }

      if (rect.right + scrollX - calRect.width >= 0) {
        // has left enough space
        return;
      }

      picker.ui.style.left = `auto`;
      picker.ui.style.right = `20px`;
    });

    const handleNumberOfMonths = () => {
      if (isResponsiveScreen()) {
        picker.options.numberOfMonths = picker.options.mobile.monthsMobile;
        return;
      }

      // special screens where two columns of the datepicker do not fit
      if (isResponsiveScreen(840)) {
        picker.options.numberOfMonths = 1;
        return;
      }

      picker.options.numberOfMonths = picker.options.mobile.monthsDesktop;
    };

    // close datepicker on window resize
    let prevWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if (window.innerWidth !== prevWidth) {
        picker.hide();
      }
    });
  },
});
