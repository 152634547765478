import formValidator from '../form/formValidator';

const report = (url) => ({
  ...formValidator(),

  step: 1,
  continueToStep2: false,
  reason: '',

  async submit() {
    this.isFormSubmittable = false;

    let formData = {
      email: this.$refs.email.value,
      reason: this.reason,
      note: this.$refs.note.value,
    };

    let response = await fetch(url, {
      method: 'POST',
      headers: {
        'x-requested-with': 'XMLHttpRequest',
        'content-type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      this.success = true;
      this.$dispatch('success');
      this.resetForm();
    }
  },
  resetForm() {
    this.step = 1;
    this.reason = '';
    this.$refs.email.value = '';
    this.$refs.note.value = '';
    this.continueToStep2 = false;
    this.reset();
  },
  // override input event for textarea
  input(event) {
    this.validateField(event, true);
    this.setFormSubmittable();
  },
});

export default report;
