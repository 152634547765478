import tagmanager from '../../../abstracts/tagmanager';

const summerregion = () => ({
  autocompleteSelect(event) {
    tagmanager.event(`summerregion-search`, {
      Category: 'summerregion',
      Summerregion: event.detail.attributes['nameAlias'],
      Country: event.detail.attributes['state'],
      Federalstate: event.detail.attributes['federalState'],
    });

    this.$nextTick(() => {
      window.location.href = event.detail.attributes['link'] + window.location.search;
    });
  },
});

export default summerregion;
