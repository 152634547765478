import { getCookie } from '../abstracts/cookie';

const user = {
  search: {
    dateStart: null,
    dateEnd: null,
  },
  newDesign: false,

  init() {
    this.search.dateStart = getCookie('ReiseDatumVon');
    this.search.dateEnd = getCookie('ReiseDatumBis');
    this.newDesign = getCookie('newdesign')?.length > 0;
  },
};

export default user;
