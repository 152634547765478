import { isResponsiveScreen } from '../../../abstracts/responsive';

const defaultOptions = {
  fullscreenTitle: 'Toggle fullscreen',
};

const icon_max =
  '<svg class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><g clip-path="url(#a)"><path class="pswp__icon-max" fill="#fff" fill-rule="evenodd" d="M12.146 7.146a.5.5 0 1 0 .708.708L19 1.707V7.5a.5.5 0 1 0 1 0V0h-7.5a.5.5 0 1 0 0 1h5.793l-6.146 6.146ZM.502 20H7.5a.5.5 0 0 0 0-1H1.707l6.147-6.146a.5.5 0 0 0-.708-.708L1 18.293V12.5a.5.5 0 0 0-1 0V20h.501Z" clip-rule="evenodd"/><path class="pswp__icon-min" fill="#fff" fill-rule="evenodd" d="M19.854.854a.5.5 0 1 0-.707-.708L13 6.293V.5a.5.5 0 0 0-1 0V8h7.5a.5.5 0 0 0 0-1h-5.793L19.854.854ZM.146 19.146a.5.5 0 1 0 .708.707L7 13.707V19.5a.5.5 0 0 0 1 0V12H.5a.5.5 0 0 0 0 1h5.793L.146 19.146Z" clip-rule="evenodd"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>';

export default class PhotoSwipeFullscreen {
  constructor(lightbox, options) {
    this.options = {
      ...defaultOptions,
      ...options,
    };
    this.lightbox = lightbox;

    this.fullscreenAPI = this.getFullscreenAPI();
    if (!this.fullscreenAPI) {
      return;
    }

    this.pswpContainer = this.getContainer();

    this.lightbox.on('init', () => {
      this.initPlugin(this.lightbox.pswp);
    });

    this.lightbox.on('beforeOpen', () => {
      this.pswpContainer.style.display = 'block';
    });

    this.lightbox.on('destroy', () => {
      this.pswpContainer.style.display = 'none';
    });
  }

  initPlugin(pswp) {
    pswp.options.appendToEl = this.pswpContainer;
    pswp.options.openPromise = this.getFullscreenPromise();

    pswp.on('uiRegister', () => {
      pswp.ui.registerElement({
        name: 'fullscreen-button',
        title: this.options.fullscreenTitle,
        order: 9,
        isButton: true,
        html: icon_max,
        onClick: () => {
          this.toggleFullscreen();
        },
      });
    });

    this.lightbox.on('close', () => {
      if (this.fullscreenAPI.isFullscreen()) {
        this.fullscreenAPI.exit();
      }
    });
  }

  getFullscreenPromise() {
    return new Promise((resolve) => {
      if (this.fullscreenAPI.isFullscreen()) {
        resolve();
        return;
      }

      document.addEventListener(
        this.fullscreenAPI.change,
        (event) => {
          setTimeout(() => {
            resolve();
          }, 300);
        },
        { once: true },
      );

      setTimeout(() => {
        if (
          this.lightbox.pswp.currSlide?.data?.element?.dataset?.fullscreen &&
          isResponsiveScreen()
        ) {
          this.fullscreenAPI.request(this.pswpContainer);
        }
      }, 300);
    });
  }

  getContainer() {
    const pswpContainer = document.createElement('div');
    pswpContainer.style.display = 'none';
    document.body.appendChild(pswpContainer);
    return pswpContainer;
  }

  toggleFullscreen() {
    if (!this.fullscreenAPI) {
      return;
    }

    if (this.fullscreenAPI.isFullscreen()) {
      this.fullscreenAPI.exit();
      return;
    }

    this.fullscreenAPI.request(this.pswpContainer);
  }

  getFullscreenAPI() {
    let api;
    let enterFS;
    let exitFS;
    let elementFS;
    let changeEvent;
    let errorEvent;

    if (document.fullscreenEnabled) {
      enterFS = 'requestFullscreen';
      exitFS = 'exitFullscreen';
      elementFS = 'fullscreenElement';
      changeEvent = 'fullscreenchange';
      errorEvent = 'fullscreenerror';
    } else if (document.webkitFullscreenEnabled) {
      enterFS = 'webkitRequestFullscreen';
      exitFS = 'webkitExitFullscreen';
      elementFS = 'webkitFullscreenElement';
      changeEvent = 'webkitfullscreenchange';
      errorEvent = 'webkitfullscreenerror';
    }
    if (enterFS) {
      api = {
        request: function (el) {
          if (enterFS === 'webkitRequestFullscreen') {
            el[enterFS](Element.ALLOW_KEYBOARD_INPUT);
          } else {
            el[enterFS]();
          }
        },
        exit: function () {
          return document[exitFS]();
        },
        isFullscreen: function () {
          return document[elementFS];
        },
        change: changeEvent,
        error: errorEvent,
      };
    }
    return api;
  }
}
