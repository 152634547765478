import autocompleter from '../form/autocompleter';
import { getCookie } from '../../abstracts/cookie';

const headerAutocompleter = (data) => ({
  ...autocompleter(data),

  afterInit() {
    let recentPages = getCookie('recentPages');
    if (!recentPages || recentPages.length <= 0) {
      return;
    }

    const pages = JSON.parse(decodeURIComponent(recentPages));
    if (pages.length <= 0) {
      return;
    }
    pages.forEach((page) => {
      this.items.push({ label: page['title'], id: '', value: page['title'], attributes: page });
    });

    this.defaultItems = this.items;
  },

  async fetchAsync() {
    if (!bergfex.search.autocompleteEnabled) {
      return '';
    }

    this.endpoint.searchParams.set('q', this.value ?? '');

    const data = new URLSearchParams();
    data.append('text', this.value);
    data.append('season', bergfex.search.season);
    data.append('lang', bergfex.search.language);
    data.append('mode', 2);

    let response = await fetch(this.endpoint.toString(), {
      method: 'POST',
      headers: {
        'x-requested-with': 'XMLHttpRequest',
      },
      redirect: 'follow',
      body: data,
    });
    return await response.text();
  },

  convertResponseToData(data) {
    if (data.length <= 0) {
      return;
    }
    data = JSON.parse(data);

    if (data.error) {
      return;
    }

    this.items = [];
    this.$refs.list.scrollTop = 0;
    this.selected = null;

    data['terms']?.forEach((line) => {
      this.items.push({ label: line['name'], id: '', value: line['name'], attributes: line });
    });
  },
});

export default headerAutocompleter;
