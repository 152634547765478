import autocompleter from './autocompleter';

const autcompleterJson = (data) => ({
  ...autocompleter(data),

  convertResponseToData(data) {
    if (data.length <= 0) {
      return;
    }
    data = JSON.parse(data);
    if (data.error) {
      return;
    }

    this.items = [];

    data['result'].forEach((item) => {
      this.items.push({
        label: item['name'],
        id: item['id'],
        value: item['name'],
        attributes: item,
      });
    });
  },
});

export default autcompleterJson;
