import scrollLock from '../../abstracts/scrollLock';
import { isResponsiveScreen } from '../../abstracts/responsive';

const autocompleter = (data) => ({
  value: '',
  id: '',
  endpoint: '',
  open: false,
  focused: false,
  selected: null,
  items: [],
  defaultItems: false,
  autocomplete: null,
  selectedId: null,
  minLength: 0,
  isLoading: false,
  disableMouseHover: false,
  queryParams: [],

  init() {
    this.value = data.value;
    this.id = data.id;
    this.endpoint = new URL(data.endpoint, document.location);
    this.minLength = data.minLength ?? 0;
    this.autocomplete = this.$refs.autocomplete;
    this.queryParams = data.queryParams;

    window.addEventListener('popstate', (event) => {
      if (isResponsiveScreen() && this.open) {
        this.closeList();
      }
    });

    this.afterInit?.();
  },

  setValue(item) {
    this.value = item.label;
  },

  selectItem(item) {
    this.setValue(item);
    this.selectedId = item.id;
    isResponsiveScreen() && history.back();
    this.closeList();
    this.$dispatch('autocompleter-item-selected', item);
  },

  isSubmittable() {
    return this.minLength === 0 || this.value.length >= this.minLength;
  },

  focusEvent(evt) {
    evt.stopPropagation();
    this.$nextTick(() => {
      this.openList();
    });

    this.focused = true;
    if (!this.isSubmittable()) {
      return;
    }

    this.fetchList();
  },

  blurEvent() {
    this.focused = false;

    if (!isResponsiveScreen()) {
      this.open = false;
    }
  },

  setDefaultItems() {
    this.items = this.defaultItems || [];
  },

  clickInput() {
    this.fetchList();

    this.$nextTick(() => {
      this.openList();
    });

    if (isResponsiveScreen()) {
      this.focusOnAutocomplete();
    }
  },

  async inputDebounce() {
    if (this.minLength > 0 && this.value.length < this.minLength) {
      this.setDefaultItems();
      return;
    }

    await this.fetchList();
    this.openList();
  },

  async fetchList() {
    if (this.minLength > 0 && this.value.length < this.minLength) {
      return;
    }

    this.isLoading = true;

    this.convertResponseToData(await this.fetchAsync());

    this.$nextTick(() => {
      this.isLoading = false;
    });
  },

  keypressEnter(ev) {
    if (this.selected === null || this.items.length <= 0) {
      this.$dispatch('autocompleter-submit', this.value);
      return;
    }
    ev.preventDefault();
    let item = this.items[this.selected];
    this.setValue(item);
    this.selectedId = item.id;
    this.$dispatch('autocompleter-item-selected', item);
  },

  focusOnAutocomplete() {
    // for IOS to async toggle the focus on the input we need to fake it first
    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.setAttribute('readonly', 'true');
    fakeInput.style.position = 'fixed';
    fakeInput.style.top = '48%';
    fakeInput.style.opacity = '0';
    fakeInput.style.fontSize = '16px';

    this.$root.prepend(fakeInput);
    fakeInput.focus();

    this.$nextTick(() => {
      setTimeout(() => {
        this.autocomplete.focus();
        fakeInput.remove();
      }, 300);
    });
  },

  openList() {
    if (this.open) {
      return;
    }

    isResponsiveScreen() && window.history.pushState({ open: this.open }, '', '');
    isResponsiveScreen() && scrollLock.lock();

    this.open = isResponsiveScreen() || this.items.length > 0;
  },

  async fetchAsync() {
    this.endpoint.searchParams.set('q', this.value ?? '');

    let queryParams = JSON.parse(this.queryParams);

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        this.endpoint.searchParams.set(key, value);
      }
    }

    let response = await fetch(this.endpoint.toString());
    return await response.text();
  },

  convertResponseToData(data) {
    //reset everything
    this.setDefaultItems();
    this.$refs.list.scrollTop = 0;
    this.selected = null; //reset index with new list
    data.split('\n').forEach((line) => {
      if (line !== '') {
        let attributes = line.split('|'); // split attributes

        this.items.push({
          label: attributes[0],
          id: attributes[1],
          value: attributes[2],
          attributes: attributes,
        });
      }
    });
  },

  keypressArrowUp() {
    this.keypressArrowEvent('up');
  },

  keypressArrowDown() {
    this.keypressArrowEvent('down');
  },

  keypressEscape() {
    this.closeList();
  },

  keypressTab() {
    this.closeList();
  },

  onAutocompleteMouseMove() {
    this.disableMouseHover = false;
  },

  onAutocompleteHover(index) {
    if (this.disableMouseHover) {
      return;
    }
    this.selected = index;
  },

  keypressArrowEvent(key) {
    if (!key) {
      return;
    }

    this.disableMouseHover = true;

    if (!this.open) {
      this.scrollIntoView('top'); // move first item into view
      this.selected = null; //reset index with new list
      return;
    }

    if (key === 'up' && this.selected === null) {
      this.selected = this.items.length - 1;
      this.scrollIntoView('bottom'); // move last item into view
    } else if (key === 'up' && this.selected <= 0) {
      this.selected = this.items.length - 1;
      this.scrollIntoView('bottom'); // move last item into view
    } else if (key === 'up') {
      this.selected--;
      this.scrollIntoView('up');
    } else if (key === 'down' && this.selected === null) {
      this.selected = 0; // move nowhere
    } else if (this.selected >= this.items.length - 1) {
      this.selected = 0;
      this.scrollIntoView('top'); // move first item into view
    } else if (key === 'down') {
      this.selected++;
      this.scrollIntoView('down');
    }
  },

  scrollIntoView(direction) {
    let list = this.$refs.list;

    if (direction === 'top') {
      list.scrollTop = 0;
      return;
    }

    let itemRect = list.children[this.selected + 1].getBoundingClientRect();

    if (direction === 'bottom') {
      list.scrollTop = itemRect.top;
      return;
    }

    if (direction === 'up') {
      if (list.scrollTop - itemRect.height < 0) {
        list.scrollTop = 0;
      } else {
        list.scrollTop -= itemRect.height;
      }
      return;
    }

    if (itemRect.bottom + itemRect.height >= list.getBoundingClientRect().bottom) {
      list.scrollTop += itemRect.height; //direction === 'down'
    }
  },

  closeList() {
    if (!this.open) {
      return;
    }

    this.focused = false;
    this.clear();

    setTimeout(() => {
      this.$dispatch('autocompleter-close', true);
    }, 300);
  },

  clear() {
    this.open = false;
    this.setDefaultItems();
    this.selected = null;

    isResponsiveScreen() && scrollLock.unlock();
  },

  reset() {
    this.value = '';
    this.selectedId = null;

    if (this.isSubmittable()) {
      this.fetchList();
    } else {
      this.setDefaultItems();
      this.open = isResponsiveScreen();
      this.autocomplete.focus();
    }

    this.$dispatch('autocompleter-reset', true);
  },
});

export default autocompleter;
