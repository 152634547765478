import { BREAKPOINT_SM } from '../../abstracts/responsive';
import { getCookie, setCookie } from '../../abstracts/cookie';

const stickyBanner = (settings) => ({
  showBanner: false,
  banner: null,
  init() {
    if (window.innerWidth > BREAKPOINT_SM || parseInt(getCookie(settings.cookie.name)) === 1) {
      return; // show only on mobile && do not show if cookie is set
    }

    this.initBanner();
    document.addEventListener('scroll', this.toggleBanner.bind(this));
  },

  close() {
    this.showBanner = false;
    this.banner = null;
    document.removeEventListener('scroll', this.toggleBanner);
    setCookie(settings.cookie.name, 1, settings.cookie.expire);
  },

  toggleBanner() {
    this.showBanner = window.scrollY > 0;
  },

  initBanner() {
    let logo = !settings.logo
      ? ''
      : `<img class="tw-pr-2 tw-shrink" src="${settings.logo}" alt="Logo" width="36" height="36"/>`;

    this.banner = `
            <div class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-brand-dark tw-w-full tw-z-40 tw-flex tw-items-center" x-show="showBanner" >
                <button @click="close()" class="tw-text-white tw-pl-4 tw-py-5 tw-pr-3 tw-text-center tw-text-12px "><i class="icon-close"></i></button>
                ${logo}
                <div class="tw-py-4 ">
                    <div class="tw-text-white tw-text-m tw-font-semibold">${settings.heading}</div>
                    <div class="tw-text-white tw-text-s ">${settings.subtitle}</div>
                </div>
                <div class="tw-px-4 tw-ml-auto">
                    <a href="${settings.button.link}" class="tw-block tw-text-s tw-font-semibold tw-text-white tw-bg-brand hover:tw-bg-brand-dark hover:tw-ring-brand-dark tw-py-2.5 tw-px-3 tw-rounded-lg">
                        ${settings.button.text}
                    </a>
                </div>
            </div>`;
  },
});

export default stickyBanner;
