const input = (attrId) => ({
  value: null,
  id: '',

  init() {
    this.id = this.$id(attrId);
  },

  reset() {
    this.$dispatch('reset', this.value);
    this.value = '';
  },

  keypressEscape(event) {
    this.$dispatch('input-keypress-escape', event);
  },

  keypressTab(event) {
    this.$dispatch('input-keypress-tab', event);
  },

  keypressArrowUp(event) {
    this.$dispatch('input-keypress-arrow-up', event);
  },

  keypressArrowDown(event) {
    this.$dispatch('input-keypress-arrow-down', event);
  },

  keypressEnter(event) {
    this.$dispatch('input-keypress-enter', event);
  },

  inputDebounce(event) {
    this.$dispatch('input-debounce', event);
  },

  focusEvent(event) {
    this.$dispatch('input-focus', event);
  },

  blurEvent(event) {
    this.$dispatch('input-blur', event);
  },

  clickInput(event) {
    this.$dispatch('input-click', event);
  },
});

export default input;
