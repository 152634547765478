import Splide from '@splidejs/splide';
import { BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS } from '../../abstracts/responsive';

const slider = ({
  padding = 1,
  snap = 0,
  progress = 1,
  controls = 1,
  gap = 0,
  drag = true,
  mobileVisibleSlides = 3,
}) => ({
  possibleSlides: 1,
  slider: null,
  position: 'first',

  init() {
    this.initSlider();
  },

  async initSlider() {
    const hasPadding = !!parseInt(padding, 10);
    const sliderGap = gap.length > 1 ? gap : '1rem';

    this.slider = new Splide(this.$el, {
      type: 'slide',
      snap: !!parseInt(snap, 10),
      perMove: 1,
      drag: drag,
      live: true,
      keyboard: true,
      omitEnd: true,
      arrows: !!parseInt(controls, 10),
      pagination: false,
      lazyLoad: 'sequential',
      autoWidth: true,
      flickPower: 400,
      breakpoints: {
        [BREAKPOINT_MD - 1]: {
          focus: hasPadding ? 'center' : 'left',
          gap: hasPadding ? sliderGap : 0,
          padding: hasPadding ? '2.75rem' : '',
        },
        [BREAKPOINT_SM]: {
          padding: hasPadding ? '1.5rem' : '',
        },
        [BREAKPOINT_XS]: {
          padding: hasPadding ? '1rem' : '',
        },
      },
    });

    this.slider.on('ready', () => {
      const Slides = this.slider.Components.Slides.filter('.js-splide-active');
      let index = this.slider.Components.Controller.getIndex();
      if (Slides[0]) {
        index = Slides[0].index;
      }
      this.toggleControls(index);
    });

    this.slider.on('move', (e) => {
      this.toggleControls(e);
    });

    if (!!parseInt(progress, 10)) {
      // noinspection JSIgnoredPromiseFromCall
      await this.progressBarSlider();
    } else {
      this.slider.mount();
    }

    if (this.slider.Components.Slides.getLength() <= mobileVisibleSlides) {
      this.slider.options = {
        focus: 'left',
      };
    }

    await this.$nextTick();
    const Slides = this.slider.Components.Slides.filter('.js-splide-active');
    if (Slides && Slides[0]) {
      this.slider.go(Slides[0].index);
    }
  },

  toggleControls(index) {
    if (index === 0) {
      this.position = 'first';
      this.slider.Components.Arrows.arrows.prev?.classList.add('tw-opacity-40');
      this.slider.Components.Arrows.arrows.next?.classList.remove('tw-opacity-40');
    } else if (index === this.slider.Components.Controller.getEnd()) {
      this.position = 'last';
      this.slider.Components.Arrows.arrows.next?.classList.add('tw-opacity-40');
      this.slider.Components.Arrows.arrows.prev?.classList.remove('tw-opacity-40');
    } else {
      this.position = 'middle';
      this.slider.Components.Arrows.arrows.prev?.classList.remove('tw-opacity-40');
      this.slider.Components.Arrows.arrows.next?.classList.remove('tw-opacity-40');
    }
  },

  async progressBarSlider() {
    const progressBarSlider = new Splide(this.$refs.progressbar, {
      perMove: 1,
      drag: true,
      live: true,
      arrows: false,
      direction: 'rtl',
      perPage: this.possibleSlides + 1,
      noDrag: '.no-drag',
    });

    this.slider.mount();
    this.possibleSlides = this.slider.Components.Controller.getEnd();
    progressBarSlider.mount();
    await this.$nextTick();
    this.slider.sync(progressBarSlider);
    progressBarSlider.options = {
      perPage: this.possibleSlides + 1,
    };
    progressBarSlider.refresh();

    this.slider.on('resized updated', () => {
      this.possibleSlides = this.slider.Components.Controller.getEnd();
      progressBarSlider.options = {
        perPage: this.possibleSlides + 1,
      };
      progressBarSlider.refresh();
    });

    progressBarSlider.on('click', (slide) => {
      progressBarSlider.go(this.possibleSlides - (slide.index - progressBarSlider.index));
    });
  },
});

export default slider;
