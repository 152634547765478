const favorites = (refType, refId, allListIds, userId, isFavorite) => ({
  state: isFavorite === '1' || isFavorite === true ? 'remove' : 'add',
  endpointAdd: '/api/favorites/v2/entries',
  endpointRemove: `/api/favorites/v2/entries/${refType}/${refId}`,
  heartContent: null,

  getData() {
    const data = new FormData();
    data.append('referenceId', parseInt(refId, 10));
    data.append('reference', refType);
    data.append('userId', userId);

    return data;
  },

  updateHeartContent() {
    if (this.state === 'add') {
      this.heartContent = `
                <i class="icon-favorite-o-s tw-text-12px group-hover:tw-text-brand tw-transition-colors"></i>
                <span class="tw-text-s group-hover:tw-text-brand tw-transition">${bergfex.favorite.save}</span>
            `;
    } else {
      this.heartContent = `
                <i class="icon-favorite-s tw-text-12px tw-text-brand"></i>
                <span class="tw-text-s group-hover:tw-text-brand tw-transition">${bergfex.favorite.saved}</span>
            `;
    }
  },

  validateAdded(data) {
    const { rootList } = data;
    return rootList.filter((obj) => obj.id === refId);
  },

  validateRemoval(data, listId) {
    const targetList = data.lists.filter((obj) => obj.id === listId)[0];

    if (targetList) {
      return targetList.favorites.indexOf(refId) === -1;
    }

    return data.rootList.every((v) => v != refId);
  },

  call() {
    this.state === 'add' ? this.add() : this.remove();
  },

  async add() {
    if (!refType || !refId || !userId) {
      return;
    }

    const data = this.getData();
    const response = await this.sendRequest(this.endpointAdd, {
      method: 'POST',
      body: data,
    });

    if (response) {
      this.state = 'remove';

      this.updateHeartContent();
      this.toggle?.();
      this.$notify.success(
        bergfex.favorite.success.add.headline,
        bergfex.favorite.success.add.text,
      );
    }
  },

  async remove() {
    if (!refType || !refId) {
      return;
    }

    const arrListIds = JSON.parse(allListIds);
    const successful = await Promise.all(
      arrListIds.map((id) =>
        fetch(`${this.endpointRemove}/${id}`, { method: 'DELETE' })
          .then((response) => response.json())
          .then((data) => this.validateRemoval(data, id))
          .catch(() => {
            this.$notify.error(bergfex.favorite.error.headline, bergfex.favorite.error.text);
          }),
      ),
    ).then((data) => data.every((v) => v === true));

    if (successful) {
      this.state = 'add';

      this.updateHeartContent();
      this.$notify.success(
        bergfex.favorite.success.remove.headline,
        bergfex.favorite.success.remove.text,
      );
    }
  },

  async sendRequest(endpoint, args) {
    try {
      const res = await fetch(endpoint, args);

      return this.validateAdded(await res.json());
    } catch (e) {
      this.$notify.error(bergfex.favorite.error.headline, bergfex.favorite.error.text);

      return false;
    }
  },
});

export default favorites;
