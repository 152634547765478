const tooltip = (text) => ({
  '@mouseenter'() {
    if ('ontouchstart' in document.documentElement) {
      // disable mobile touch
      return;
    }

    clearTimeout(this.tooltipTimeout);

    if (!this.tooltip) {
      this.tooltipParent = document.createElement('div');
      this.tooltip = document.createElement('div');
    }
    this.tooltipParent.classList.add('tailwind');
    this.tooltipParent.appendChild(this.tooltip);
    this.tooltip.classList.add(
      'tw-transition-opacity',
      'tw-opacity-0',
      'tw-max-w-sm',
      'tw-z-50',
      'tw-absolute',
    );
    document.body.appendChild(this.tooltipParent);
    this.tooltip.innerHTML = `
            <div class='tw-w-2 tw-h-2 tw-rotate-45 tw-mx-auto -tw-mb-1 tw-bg-black'></div>
            <div class="tw-text-white tw-bg-black tw-px-2 tw-py-1 tw-rounded tw-text-s tw-leading-16 tw-text-center tw-max-w-[180px]">${text}</div>
        `;

    // set position
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    const position = this.$el.getBoundingClientRect();

    this.tooltip.style.top = scrollPosition + position.y + this.$el.offsetHeight + 4 + 'px';

    const left = position.x + this.$el.offsetWidth / 2 - this.tooltip.offsetWidth / 2;
    this.tooltip.style.left = (left < 10 ? 10 : left) + 'px';

    if (left + this.tooltip.offsetWidth > window.innerWidth) {
      this.tooltip.style.left = 'auto';
      this.tooltip.style.right = '10px';
    }

    // show tooltip
    this.tooltip.classList.remove('tw-opacity-0');
    this.tooltip.classList.add('tw-opacity-100');
  },

  '@mouseleave'() {
    if (!this.tooltip) {
      return;
    }

    this.tooltip.classList.remove('tw-opacity-100');
    this.tooltip.classList.add('tw-opacity-0');
    this.tooltipTimeout = setTimeout(() => {
      this.tooltipParent.remove();
      this.tooltipParent = null;
      this.tooltip = null;
    }, 300);
  },
});

export default tooltip;
